<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'">{{
                            $t("message.new_m", {
                                m: $t("message.check"),
                            })
              }}</p>
          <div>
            <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4"  v-loading="loadingData" :class="mode ? 'table__myday' : 'table__mynight'">
            <el-form ref="form" :model="form" :rules="rules">

                <el-row :gutter="20">

                    <el-col :span="8">
                        <el-form-item prop="name">
                                <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                    <span class="start_top">*</span>
                                    {{$t('message.name')}}
                                </span>
                                <el-input
                                :class="mode ? 'input__day' : 'input__night'"
                                    :placeholder="$t('message.name')"
                                    v-model="form.name"
                                    size="medium"
                                ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                     <el-col :span="8">
                        <el-form-item prop="branch_name">
                                <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                    <span class="start_top">*</span>
                                    {{
                                        $t("message.nameGiven", {
                                            m: $t("message.branch"),
                                        })
                                    }}
                                </span>
                                <el-input
                                :class="mode ? 'input__day' : 'input__night'"
                                    :placeholder="$t('message.nameGiven', { m: $t('message.branch'), })"
                                    v-model="form.branch_name"
                                    size="medium"
                                ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                     <el-col :span="8">
                        <el-form-item prop="mfo">
                                <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                   {{$t('message.mfo')}}
                                </span>
                                <el-input
                                :class="mode ? 'input__day' : 'input__night'"
                                    :placeholder="$t('message.mfo')"
                                    v-model="form.mfo"
                                    size="medium"
                                ></el-input>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                     <el-col :span="8">
                        <el-form-item prop="number">
                                <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                    {{$t('message.number')}}
                                </span>
                                <crm-input
                                    :class="mode ? 'input__day' : 'input__night'"
                                    :placeholder="$t('message.number')"
                                    :inputValue="form.number"
                                    :type="'number'"
                                    v-model="form.number"
                                    size="medium"
                                ></crm-input>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                        <el-col :span="8">
                        <el-form-item prop="currency_id">
                                <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                    {{$t('message.currency')}}
                                </span>
                                <select-currency
                                :placeholder="columns.currency_id.title"
                                :id="form.currency_id"
                                v-model="form.currency_id"
                            ></select-currency>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="8">
                        <el-form-item>
                                <span class="input--label d-block mb-0 line-h-24" :class="mode ? 'text__day2' : 'text__night2'">
                                    {{$t('message.default')}}
                                </span>
                                 <el-switch 
                                    v-model="form.active"
                                    
                                 ></el-switch>
                        </el-form-item>
                    </el-col>
                    <!-- end-col -->

                </el-row>

            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->

       
    </div>
  </div>
</template>
<script>
import selectCurrency from "@/components/filters/inventory/select-currency";
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";
import show from "@/utils/mixins/show";
export default {
    mixins: [form, drawer,show],
    name: "AccountController",
    components:{selectCurrency},
    data() {
        return {};
    },
    created() {},
    computed: {
        ...mapGetters({
            rules: "account/rules",
            model: "account/model",
            columns: "account/columns",
            mode:'MODE'
        }),
    },
    methods: {
        ...mapActions({
            save: "account/update",
            show: "account/show",
        }),
        submit(close = true) {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingButton = true;
                    this.save(this.form)
                        .then((res) => {
                            this.loadingButton = false;
                            this.$alert(res);
                            this.parent().listChanged();
                            if (close) this.close();
                        })
                        .catch((err) => {
                            this.loadingButton = false;
                            this.$alert(err);
                        });
                }
            });
        },
    },
};
</script>
