<template>
    <div class="p-pegas">
        <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="card-table-body ifon-add-title-block">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div class="caz-blocks-sarcho-title">
                            <div class="content-title d-flex align-center mr-2" :class="mode ? 'content__titleday' : 'content__titlenight'">
                                {{ $t("message.check_a") }}
                            </div>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <el-date-picker
                                        v-model="filterForm.from_date"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        size="small"
                                        type="date"
                                        format="dd.MM.yyyy"
                                        value-format="dd.MM.yyyy"
                                        class="mr-3"
                                        placeholder="От"
                                    >
                                    </el-date-picker>
                                    <el-date-picker
                                        v-model="filterForm.to_date"
                                        :class="mode ? 'input__day' : 'input__night'"
                                        size="small"
                                        class="mbtop5"
                                        type="date"
                                        format="dd.MM.yyyy"
                                        value-format="dd.MM.yyyy"
                                        placeholder="До"
                                    >
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12" class="flex-style text-right">
                        <crm-create-and-column-settings
                            :permission="$options.name"
                            :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                            @c-create="drawerCreate = true"
                            :columns="columns"
                            @c-change="updateColumn"
                            :v_can_create="'AccountController@store'"
                        >
                        </crm-create-and-column-settings>
                    </el-col>
                </el-row>
            </div>
            <!-- end ifon-add-title-block -->

            <!-- <div class="w-100 d-flex align-center justify-center">
        <span class="align-items-center align-self-center pr-2">
          <span class="el-tag el-tag--success el-tag--light">
            Общая сумма: 99 704 000 Сум
          </span>
        </span>
      </div> -->

            <div class="card-table-header table-crm-smart">
                <table
                    class="table-my-code table-bordered"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                    v-loading="loadingData"
                >
                    <thead>
                        <tr>
                            <th class="w50p" v-if="columns.id.show">
                                {{ columns.id.title }}
                            </th>

                            <th v-if="columns.active.show">
                                {{ columns.active.title }}
                            </th>

                            <th v-if="columns.name.show">
                                {{ columns.name.title }}
                            </th>

                            <th v-if="columns.mfo.show">
                                {{ columns.mfo.title }}
                            </th>

                            <th v-if="columns.number.show">
                                {{ columns.number.title }}
                            </th>

                            <th v-if="columns.outgoing.show">
                                {{ columns.outgoing.title }}
                            </th>

                            <th v-if="columns.incoming.show">
                                {{ columns.incoming.title }}
                            </th>

                            <th v-if="columns.currency_id.show">
                                {{ columns.currency_id.title }}
                            </th>

                            <th v-if="columns.created_at.show">
                                {{ columns.created_at.title }}
                            </th>

                            <th v-if="columns.updated_at.show">
                                {{ columns.updated_at.title }}
                            </th>

                            <th v-if="columns.settings.show">
                                {{ columns.settings.title }}
                            </th>
                        </tr>

                        <tr class="filter_sorche">
                            <th v-if="columns.id.show">
                                <crm-input
                                    clearable
                                    size="mini"
                                    v-model="filterForm.id"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.id.title"
                                    class="id_input"
                                ></crm-input>
                            </th>

                            <th v-if="columns.active.show">
                                <crm-input
                                    size="mini"
                                    v-model="filterForm.active"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.active.title"
                                ></crm-input>
                            </th>

                            <th v-if="columns.name.show">
                                <crm-input
                                    size="mini"
                                    v-model="filterForm.name"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.name.title"
                                ></crm-input>
                            </th>

                            <th v-if="columns.mfo.show">
                                <crm-input
                                    size="mini"
                                    v-model="filterForm.mfo"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.mfo.title"
                                ></crm-input>
                            </th>

                            <th v-if="columns.number.show">
                                <crm-input
                                    size="mini"
                                    v-model="filterForm.number"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.number.title"
                                ></crm-input>
                            </th>

                            <th v-if="columns.outgoing.show">
                                <crm-input
                                    size="mini"
                                    v-model="filterForm.outgoing"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.outgoing.title"
                                ></crm-input>
                            </th>

                            <th v-if="columns.incoming.show">
                                <crm-input
                                    size="mini"
                                    v-model="filterForm.incoming"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    :placeholder="columns.incoming.title"
                                ></crm-input>
                            </th>

                            <th v-if="columns.currency_id.show">
                                <select-currency
                                    size="mini"
                                    :placeholder="columns.currency_id.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.currency_id"
                                ></select-currency>
                            </th>

                            <th v-if="columns.created_at.show">
                                <crm-date-picker
                                    size="mini"
                                    :placeholder="columns.created_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.created_at"
                                >
                                </crm-date-picker>
                            </th>

                            <th v-if="columns.updated_at.show">
                                <crm-date-picker
                                    size="mini"
                                    :placeholder="columns.updated_at.title"
                                    :class="mode ? 'filter__day' : 'filter__night'"
                                    v-model="filterForm.updated_at"
                                >
                                </crm-date-picker>
                            </th>

                            <th
                                class="settinW"
                                v-if="columns.settings.show"
                            ></th>
                        </tr>
                    </thead>
                    <transition-group name="flip-list" tag="tbody">
                        <tr
                            v-for="(account, index) in list"
                            :key="'account-' + index"
                            class="cursor-pointer"
                        >
                            <td v-if="columns.id.show">
                                {{ account.id }}
                            </td>

                            <td v-if="columns.active.show" class="text-center">
                                <i
                                    v-if="account.active"
                                    class="el-icon-circle-check color-k"
                                ></i>
                                <i v-else class="el-icon-circle-close"></i>
                            </td>

                            <td v-if="columns.name.show">
                                {{ account.name }}
                            </td>

                            <td v-if="columns.mfo.show">
                                {{ account.mfo }}
                            </td>

                            <td v-if="columns.number.show">
                                {{ account.number }}
                            </td>

                            <td v-if="columns.outgoing.show">
                                {{ account.outgoing |formatNumber }}
                            </td>

                            <td v-if="columns.incoming.show">
                                {{ account.incoming | formatNumber }}
                            </td>

                            <td v-if="columns.currency_id.show">
                                {{
                                    account.currency
                                        ? account.currency.name
                                        : null
                                }}
                            </td>

                            <td v-if="columns.created_at.show">
                                {{ account.created_at }}
                            </td>

                            <td v-if="columns.updated_at.show">
                                {{ account.updated_at }}
                            </td>

                            <td
                                v-if="columns.settings.show"
                                class="settings-td"
                            >
                                <crm-settings
                                    :name="$options.name"
                                    :model="account"
                                    :actions="actions"
                                    :permissionShow="'AccountController@update'"
                                    :permissionDestroy="'AccountController@destroy'"
                                    @edit="edit"
                                    @delete="destroy"
                                ></crm-settings>
                            </td>
                        </tr>
                    </transition-group>
                </table>
                <div class="my___pagination">
                    <crm-pagination
                        @c-change="updatePagination"
                        :class="mode ? 'pagination__day' : 'pagination__night'"
                        :pagination="pagination"
                    ></crm-pagination>
                </div>
            </div>

            <div class="app-modal app-modal__full modal-color-bg">
                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerCreate"
                    size="70%"
                    ref="drawerCreate"
                    @opened="drawerOpened('drawerCreateChild')"
                    @closed="drawerClosed('drawerCreateChild')"
                >
                    <crm-create
                        ref="drawerCreateChild"
                        drawer="drawerCreate"
                    ></crm-create>
                </el-drawer>

                <el-drawer
                    :with-header="false"
                    :visible.sync="drawerUpdate"
                    size="70%"
                    ref="drawerUpdate"
                    @opened="drawerOpened('drawerUpdateChild')"
                    @closed="drawerClosed('drawerUpdateChild')"
                >
                    <crm-update
                        :selectedItem="selectedItem"
                        ref="drawerUpdateChild"
                        drawer="drawerUpdate"
                    ></crm-update>
                </el-drawer>
            </div>
        </div>
    </div>
</template>

<script>
import list from "@/utils/mixins/list";
import CrmCreate from "./components/crm-create";
import CrmUpdate from "./components/crm-update";
import selectCurrency from "@/components/filters/inventory/select-currency";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
    name: "AccountController",
    mixins: [list],
    components: {
        CrmCreate,
        CrmUpdate,
        selectCurrency,
    },

    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            list: "account/list",
            columns: "account/columns",
            pagination: "account/pagination",
            filter: "account/filter",
            sort: "account/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updatePagination: "account/updatePagination",
            setPagination: "account/setPagination",
            updateFilter: "account/updateFilter",
            updateColumn: "account/updateColumn",
            refreshData: "account/refreshData",
            updateSort: "account/updateSort",
            updateList: "account/index",
            delete: "account/destroy",
            empty: "account/empty",
            show: "account/show",
        }),
    },
};
</script>
<style scoped>
.color-k {
    font-size: 20px;
    color: chartreuse;
}
</style>
